import React from "react";

const Strategy = () => {
  return (
    <div className="strategy_sect" id="strategy">
      <div className="section_topbar">
        <div className="item">Our strategy</div>
      </div>
      <div className="section_content">
        <div className="section_text">
          <div className="first_text">
            At Guineabod, we understand that customer satisfaction starts with
            providing them with high grade products and services
          </div>
          <div className="inner">
            <div className="illust"></div>
            <div className="texts">
              <p className="sub_header">We’re Building Better Products</p>
              <p className="sub_text">
                Our manufacturing ability allows us to make products designed
                specifically for your business, tailored exactly to your needs
                and printed with your own company design, branding or messaging
                We provide high guaranty on our products and try to ensure that
                common polythene problems such as side leakages, ink odour and
                taste are non existent
              </p>
            </div>
          </div>
          <div className="inner">
            <div className="illust"></div>
            <div className="texts">
              <p className="sub_header">We listen to customer's feedback</p>
              <p className="sub_text">
                We value customers' inisight on how we can provide them with
                better goods and learn from their complaints on defective
                products
              </p>
            </div>
          </div>
        </div>
        <div className="section_image">
          <img src="img/services.webp" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Strategy;
