import React from "react";

const Gallery = () => {
  const data = [
    {
      image: "bread_nylon",
      title: "Bread Nylon",
      info: "This nylon is build to be shiny in nature and beautiful in order to atttract consumers towards it.It is used maily in the packaging of bread",
    },
    {
      image: "toromagbe",
      title: "Black Shopping Bag",
      info: "This nylon is built to be used to aid mostly in the marketplace to group small products into a tiny bunch for easy handling.",
    },
    {
      image: "plain_nylon",
      title: "Plain Nylon",
      info: "This nylon is usually transparent and when cut can be used to package products like groundnut oil,sugar in little quantities at marketplaces.",
    },
    {
      image: "printed_roll",
      title: "Printed Roll Nylon",
      info: "This nylon is usually branded with a company design or message. It is cut and then used to package sachet water to be consumed.",
    },
    {
      image: "bread_roll",
      title: "Chin Chin Roll",
      info: "This is chin chin nylon in roll form.It can come in different sizes and colours(plain or branded with your design).It is used to package several sizes of chin chin products when cut",
    },
    {
      image: "packing_bag",
      title: "PureWater Packing Bag",
      info: "This is a nylon built to be durable to avoid side and under tears.It is mostly used to hold and package group of sachet water together",
    },
    {
      image: "bin",
      title: "Dustbin Bag",
      info: "This nylon is usually black and meant to be used for waste disposal",
    },
    {
      image: "plastic_mulch",
      title: "Plastic Mulch",
      info: "This is a nylon roll built to be black and thick mainly to hold soil and water in it. It is mainly used in the agriculture industry in the nursery plant",
    },
    {
      image: "cut_mulch",
      title: "Agricultural Planting Bag",
      info: "This is a nylon built to be black and thick mainly to hold soil and water in it. It is the final product of when the Agricultural Planting Roll has been cut",
    },
  ];
  return (
    <div className="gallery_sect">
      <div className="section_header">
        <p className="main">MOST POPULAR PRODUCTS</p>
      </div>

      <div className="image_slider">
        <div className="slider_inner">
          {[...data, ...data].map((item) => {
            return (
              <div className="img_cont">
                <img src={`img/${item.image}.jpeg`} alt="" />
                <div className="img_text">
                  <p className="main">{item.title}</p>
                  <p className="sub">{item.info}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
