import React from "react";

const LetsBuild = () => {
  return (
    <div className="lets_build" id="services">
      <div className="section_images">
        <div className="img_container">
          <img src="img/9.jpeg" alt="" />
        </div>
        <div className="img_container small">
          <img src="img/cut_toromagbe.jpeg" alt="" />
        </div>
      </div>
      <div className="lets_build_text">
        <div className="lets_build_text_inner">
          <p className="header_txt">Welcome to Guineabod</p>
          <p className="big_text">
            Let’s Help You <br /> Package Your Product
          </p>

          <p className="sub_text">
            30+ Years of providing goods and services to our wonderful customers
          </p>

          <p className="brief">
            Guineabod is Nigeria's leading polythene packaging manufacturer. As
            manufacturers, we cut the middleman and you deal with us directly
            which means you get
          </p>

          <ul className="services_list">
            {[
              "Quality goods at factory prices even when ordering in retail",
              "An unbeatable value on a huge range of polyethene goods ",
              "Consultation services on the product that satisfies your demand",
              "High end maintenance engineering services ",
            ].map((item) => {
              return (
                <li>
                  <i class="ph-check-circle-fill"></i>
                  <p>{item}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LetsBuild;
