import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="foot">
        <div className="block_1">
          <div className="footer_logo">Gunieabod Nigeria Limited</div>
          <div className="footer_description">
            Great Production of Packaging & Branding Materials
          </div>
        </div>
        <div className="other_block">
          <div className="contact_block">
            <div className="header">Contact</div>
            <ul>
              <li>
                <i className="ph-phone-fill" />
                <p>0802 321 1487</p>
              </li>

              <li>
                <i class="ph-map-pin-fill"></i>
                <p>
                  664 Lagos-Abeokuta Expy, Abule Egba 101232, Lagos , Nigeria
                </p>
              </li>

              <li>
                <i class="ph-envelope-fill"></i>
                <p>guineabodnigerialimited@yahoo.co.uk</p>
              </li>
            </ul>
          </div>
          {/* <div className="newsletter">
            <div className="header">Newsletter</div>
            <div className="sub_txt">
              There are many of simple lorem available for not, but the majority
              alteration
            </div>
            <div className="news_input">
              <input type="text" placeholder="Email Address" />
              <button>
                <i class="ph-paper-plane-tilt-fill"></i>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
