import React from "react";

const Navbar = () => {
  return (
    <nav>
      <div className="app_logo">Guineabod Nigeria Limited</div>

      <div className="links_period">
        <ul className="nav_links">
          <a href="#services">Our Services</a>
          <a href="#strategy">Strategy</a>
          <a href="#clients">Clients</a>
        </ul>

        <a href="#footer" className="contact">
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
