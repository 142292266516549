import React from "react";

const Industries = () => {
  return (
    <div className="industries_served" id="clients">
      <div className="section_header">
        <p className="sub">SERVICES WE’RE OFFERING</p>
        <p className="main">Industries We Serve</p>
      </div>
      <div className="industries_imgs">
        {[
          {
            title: "Packaging Industry",
            brief:
              "Guineabod offers several polythene products that are manufactured in a way to meet the packaging requirements of several industries.Example, the drinking water industry requires their nylon to have specific designs printed on it while still ensuring that the water is safe for consumption",
            img: "img/rolls.jpeg",
          },
          {
            title: "Recycling Industry ",
            brief:
              "At Guineabod,we never refuse to reuse.As we understand the adverse effects of polythene on the environments,we aim to help curb this by actively contributing to the recylcling of products.This encourages consumers to properly dispose their waste and save the Earth for a better tomorrow",
            img: "img/recycle.jpeg",
          },
          {
            title: "Engineering Industry",
            brief:
              "At Guineabod, we offer engineering services to customers. Our engineering services ranges from consulatation services on various machinery,routine checking or preventive maintenance processes and corrective maintenance activities on damaged machinery ",
            img: "img/engineering.jpeg",
          },
        ].map((item) => {
          return (
            <div className="industry_item">
              <div className="industry_img">
                <img src={item?.img} alt="" />
              </div>
              <div className="industry_details">
                <p className="ind_title">{item?.title}</p>
                <p className="ind_brief">{item?.brief}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Industries;
