import Hero from "./sections/Hero";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Gallery from "./sections/Gallery";
import Strategy from "./sections/Strategy";
import LetsBuild from "./sections/LetsBuild";
import Industries from "./sections/Industries";

function App() {
  return (
    <>
      <div className="container">
        <Navbar />
        <div className="home_container">
          <Hero />
          <LetsBuild />
          <Industries />
          <Strategy />
          <Gallery />
        </div>

        <Footer />
      </div>
    </>
  );
}

export default App;
