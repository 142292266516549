import React from "react";

const Hero = () => {
  return (
    <div className="hero_">
      <div className="hero_text">
        <p className="main">
          Standard polythene products delivered to your doorstep
        </p>
        <p className="sub">
          At Guineabod, we provide our customers with a wide range of top grade
          polythene products that constantly exceed expectations.
        </p>

        {/* <button className="get_products">Browse product</button> */}
      </div>
      <div className="hero_design">
        <div className="hero_img">
          <img src="img/machines.jpeg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
